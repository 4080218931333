import {Autocomplete, TextField} from "@mui/material";

function AutoTextInput(props) {
    return (
        <div className="AutoTextInput">
            <Autocomplete
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '5px',
                        background: '#FFFFFF',
                        outline: "none",
                        minHeight: "50px",
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: "rgba(0, 0, 0, 0.6)",
                    },
                    '& .MuiInputLabel-root': {
                        lineHeight: "1em",
                        fontSize: "1.2rem",
                    },
                }}
                id = {props.id}
                key={props.resetKey}
                autoSelect
                options={props.searchValues}
                onInputChange={props.onInputChange}
                blurOnSelect
                clearOnBlur
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={props.label}
                        variant="standard"
                        placeholder={props.placeholder}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {props.startAdornment}
                                </>
                            )
                        }}
                    />
                }
            />
        </div>
    );
}

export default AutoTextInput;