import {Box, Typography} from "@mui/material";

function FormHeader(props) {

    return (
        <Box sx={{
            mx: 'auto',
            width: '405px',
            textAlign: 'center',
            p:1,
        }}>
            <Typography fontWeight='bold' variant='h6'>Tell us about your commute</Typography>
            <Typography variant='body1'>We analyze your local stop and take walking time into account to rate its reliability.</Typography>
        </Box>
    );
}

export default FormHeader;