import { Analytics } from 'aws-amplify';

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
        keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
    }
    return keyValuePairs.join('&');
}

export function getStopData(currentStop, currentRoute, currentDirection, currentWalkTime) {
    let queryObject = {
        leBins: '1,2,5,9'
    }

    if (currentStop) {
        queryObject["stopID"] = currentStop.stop_id
    }

    if (currentRoute) {
        queryObject["route"] = currentRoute.id
    }

    if (currentDirection) {
        queryObject["direction"] = currentDirection.id
    }

    if (currentWalkTime) {
        queryObject["walkTime"] = currentWalkTime
    }

    const queryString = objToQueryString(queryObject);
    let attributes = {}
    currentStop && (attributes.stop = currentStop.stop_name.toString())
    currentRoute && (attributes.route = currentRoute.readable_name.toString())
    currentDirection && (attributes.direction = currentDirection.readable_name.toString())
    currentWalkTime && (attributes.walkTime = currentWalkTime.toString())
    Analytics.record({name: 'loadStopData',
        attributes: attributes
    })
    return fetch(`https://rcw8avql5k.execute-api.us-east-1.amazonaws.com/Prod/get-stop-data?${queryString}`)
        .then(data => data.json())
}