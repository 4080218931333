import {Box} from "@mui/material";
import AutoTextInputSearch from "./AutoTextInputSearch";
import AutoTextInput from "./AutoTextInput";
import {useEffect, useState} from "react";
import {getStopData} from "../Api/stop_data";
import AutoTextInputMinutes from "./AutoTextInputMinutes";

function clearAutocompleteField(id, resetKey, setResetKey) {
    document.getElementById(id).value = '';
    document.getElementById(id).inputValue = '';
    setResetKey(!resetKey);
}

function getStopNamesFromAllStopData(allStopDataByName) {
    return [...allStopDataByName.keys()];
}

function getRouteNamesByStop(stopData) {
    if (stopData) {
        return [...stopData.routes.keys()];
    }
    return [];
}

function getDirectionNamesByRoute(routeData) {
    if (routeData) {
        return [...routeData.directions.keys()];
    }
    return [];
}

function setReloadFormIfReady(setReloadForm, currentStop) {
    if (currentStop != null) {
        setReloadForm(true);
    }
}

const formIDs = {
    "stop": "stopInput",
    "route": "routeInput",
    "direction": "directionInput",
    "walk": "walkInput",
}

function StopRouteDirectionForm(props) {
    const [currentStop, setCurrentStopVar] = useState(null);
    const stopOptions = getStopNamesFromAllStopData(props.allStopDataByName);
    const [currentRoute, setCurrentRouteVar] = useState(null);
    const [routeOptions, setRouteOptions] = useState([]);
    const [currentDirection, setCurrentDirectionVar] = useState(null);
    const [directionOptions, setDirectionOptions] = useState([]);

    const [routeResetKey, setRouteResetKey] = useState(false);
    const [directionResetKey, setDirectionResetKey] = useState(false);

    const [reloadForm, setReloadForm] = useState(false);

    const walkOptions = Array.from({length: 20}, (_, i) => String(i + 1));
    const [currentWalkTime, setCurrentWalkTime] = useState(null);

    useEffect(() => {
        setReloadFormIfReady(setReloadForm, currentStop, currentRoute, currentDirection, currentWalkTime);
    }, [currentStop, currentRoute, currentDirection, currentWalkTime, setReloadForm]);

    useEffect(() => {
        if (reloadForm) {
            props.setIsLoading(true);
            getStopData(currentStop, currentRoute, currentDirection, currentWalkTime)
                .then(stopData => {
                    props.setStopArrivalData(stopData);
                    props.setIsLoading(false);
                })
            setReloadForm(false);
        }
    }, [props, reloadForm, currentWalkTime, currentStop, currentRoute, currentDirection]);

    return (
        <Box margin={5}>
            <Box component="span" sx={{display: 'block', p: 1}}>
                <AutoTextInputSearch
                    id={formIDs["stop"]}
                    searchValues={stopOptions}
                    loadingAllStopData={props.loadingAllStopData}
                    onInputChange={
                        (event, value) => {
                            const stopDataMap = props.allStopDataByName.get(value);
                            setCurrentStopVar(stopDataMap);
                            setRouteOptions(getRouteNamesByStop(stopDataMap));
                            clearAutocompleteField(formIDs["route"], routeResetKey, setRouteResetKey);
                            setCurrentRouteVar(null);
                            clearAutocompleteField(formIDs["direction"], directionResetKey, setDirectionResetKey);
                            setCurrentDirectionVar(null);
                        }}
                    placeholder="Search Path stops"
                    label="Stop"
                />
            </Box>

            <Box component="span" sx={{display: 'block', p: 1}}>
                <AutoTextInputMinutes
                    id={formIDs["walk"]}
                    searchValues={walkOptions}
                    onInputChange={
                        (event, value) => {
                            setCurrentWalkTime(value);
                        }}
                    endAdornment="minutes"
                    placeholder="e.g. 6"
                    label="Walk time to platform"
                />
            </Box>

            <Box component="span" sx={{display: 'block', p: 1}}>
                <AutoTextInput
                    id={formIDs["route"]}
                    searchValues={routeOptions}
                    resetKey={routeResetKey}
                    onInputChange={
                        (event, value) => {
                            const routeDataMap = currentStop.routes.get(value);
                            setCurrentRouteVar(routeDataMap);
                            setDirectionOptions(getDirectionNamesByRoute(routeDataMap));
                            clearAutocompleteField(formIDs["direction"], directionResetKey, setDirectionResetKey);
                        }}
                    placeholder="Newark - World Trade Center"
                    label="Route"
                />
            </Box>

            <Box component="span" sx={{display: 'block', p: 1}}>
                <AutoTextInput
                    id="directionInput"
                    searchValues={directionOptions}
                    resetKey={directionResetKey}
                    onInputChange={
                        (event, value) => {
                            const directionDataMap = currentRoute.directions.get(value);
                            setCurrentDirectionVar(directionDataMap);
                        }}
                    placeholder="To WTC"
                    label="Direction"
                />
            </Box>
        </Box>
    );
}

export default StopRouteDirectionForm;