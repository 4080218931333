import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { defaults } from 'chart.js';
import {Box, Card, Skeleton, Typography, useTheme} from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            title: {
                display: true,
                text: "Wait time in minutes",
                font: {
                    family: "'Work Sans', sans-serif",
                },
            }
        },
        y: {
            display: false,
            grid: {
                display: false
            }
        }
    }
};

const labels = ['Missed', '1 Minute Or Less', '1 - 3 Minutes', '3 - 6 Minutes', '6 Or More Minutes'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Count',
            data: {'Missed': 2, '1 Minute Or Less': 2, '1 - 3 Minutes': 3, '3 - 6 Minutes': 4, '6 Or More Minutes': 5},
            barPercentage: 1.2,
            font: {
                family: "'Work Sans', sans-serif",
            },
        },
    ],
};

function getChartData(leBinPercents, theme) {
    const labels = Object.getOwnPropertyNames(leBinPercents);
    let dataSeries = {};
    for (const binLabel in leBinPercents) {
        dataSeries[binLabel] = leBinPercents[binLabel].toFixed(3)*100;
    }

    return {
        labels,
        datasets: [
            {
                label: 'Percent',
                data: dataSeries,
                backgroundColor: theme.palette.secondary.main,
                barPercentage: 1.2,
            },
        ],
    };
}

export default function ResultsCard(props) {
    const theme = useTheme();
    defaults.font.family = "Work Sans";

    if (props.isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Card
                    sx={{padding: 1.5, borderRadius:8, margin: 2, width: '80%', maxWidth: '1200px', bgcolor: props.backgroundColor}}
                >
                    <Skeleton variant="text" height={50} width={350} sx={{ marginBottom: -1}} animation="wave"/>
                    <Skeleton variant="text" height={35} width={300} animation="wave"/>
                    <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 3}} animation="wave"/>
                </Card>
            </Box>
        );
    } else if (props.stopArrivalData == null) {
        return ;
    } else {
        const stop_data = props.allStopDataByID.get(String(props.stopArrivalData.stop_id))
        const stop_name = stop_data.stop_name
        const chartData = getChartData(props.stopArrivalData.leBinPercents, theme)

        const route_data = stop_data.routes.get(String(props.stopArrivalData.filtered_by.route))
        let route_name = null
        if (route_data) {
            route_name = route_data.readable_name
        }

        const direction_data = route_data ? route_data.directions.get(String(props.stopArrivalData.filtered_by.direction)) : null
        let direction_name = null
        if (direction_data) {
            direction_name = direction_data.readable_name
        }

        const walk_data = props.stopArrivalData.filtered_by.walk_time;
        const total_trips = props.stopArrivalData.total;

        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Card sx={{padding: 1.5, borderRadius:5, margin: 2, width: '80%', maxWidth: '1200px', bgcolor: props.backgroundColor}}>
                    <Box align={"left"} sx={{margin: 1}}>
                        <Typography variant={"h6"} sx={{ fontSize: 14}}>Predicted Arrival Time Precision</Typography>
                        <Typography variant={"h5"}>{stop_name}</Typography>
                        <Typography variant={"h6"} sx={{ fontSize: 14}}>{route_name}</Typography>
                        {direction_name && <Typography variant={"h6"} sx={{ fontSize: 14}}>{direction_name}</Typography>}
                        {walk_data && <Typography variant={"h6"} sx={{ fontSize: 14}}>Walk time: {walk_data} minutes</Typography>}
                        {total_trips && <Typography variant={"h6"} sx={{ fontSize: 14}}>Total trips analyzed: {total_trips}</Typography>}
                    </Box>
                    <Bar options={options} data={chartData}/>
                </Card>
            </Box>
        );
    }
}