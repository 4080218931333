import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

function AutoTextInputSearch(props) {
    return (
        <div className="AutoTextInput">
            <Autocomplete
                id={props.id}
                onChange={props.load}
                sx={{
                    '& .MuiInputBase-root': {
                        borderRadius: '5px',
                        background: '#FFFFFF',
                        minHeight: "50px",
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: "rgba(0, 0, 0, 0.6)",
                    },
                    '& .MuiInputLabel-root': {
                        lineHeight: "1em",
                        fontSize: "1.2rem",
                    },
                }}
                loading={props.loadingAllStopData}
                autoSelect
                blurOnSelect
                clearOnBlur
                options={props.searchValues}
                onInputChange={props.onInputChange}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={props.label}
                        placeholder={props.placeholder}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            )
                        }}
                    />
                }
            />
        </div>
    );
}

export default AutoTextInputSearch;