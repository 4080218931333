/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a6b7df48-4181-4dbe-ba29-580208297723",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_mobile_analytics_app_id": "e8a9dd425aae475e89eeac4f02cf049e",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
