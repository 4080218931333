import {BottomNavigation, Box, Drawer, Paper} from "@mui/material";
import LogoBox from "./LogoBox";
import FormHeader from "./FormHeader";
import StopRouteDirectionForm from "./StopRouteDirectionForm";
import ResultsCard from "./ResultsCard";
import SummaryCard from "./SummaryCard";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import TramIcon from "@mui/icons-material/Tram";

function DesktopIndex(props) {

    const drawerWidth = 450;

    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Drawer
                PaperProps={{
                    elevation: 12,
                    bgcolor: 'primary.main',
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    elevation: 12,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        bgcolor: 'primary.main',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <LogoBox/>
                <FormHeader/>
                <StopRouteDirectionForm
                    allStopDataByName={props.allStopDataByName}
                    setIsLoading={props.setIsLoading}
                    setStopArrivalData={props.setStopArrivalData}
                    loadingAllStopData={props.loadingAllStopData}
                />
            </Drawer>

            <Box
                component="main"
                justifyContent="center"
                alignItems="center"
                sx={{flexGrow: 1, p: 3, marginTop: "10vh"}}

            >
                <ResultsCard
                    isLoading={props.isLoading}
                    stopArrivalData={props.stopArrivalData}
                    allStopDataByID={props.allStopDataByID}
                    backgroundColor={'primary.main'}
                />
                <SummaryCard
                    isLoading={props.isLoading}
                    stopArrivalData={props.stopArrivalData}
                    allStopDataByID={props.allStopDataByID}
                    backgroundColor={'primary.main'}
                />
                <Paper
                    sx={{position: 'fixed', bottom: 0, left: drawerWidth, right: 0, boxShadow: 'none'}}
                >
                    <BottomNavigation>
                        <DirectionsTransitIcon fontSize='large'
                                               sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <DirectionsBusIcon fontSize='large'
                                           sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <DirectionsBoatIcon fontSize='large'
                                            sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <TramIcon fontSize='large' sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                    </BottomNavigation>
                </Paper>
            </Box>
        </Box>
    );
}

export default DesktopIndex;