import {Box, Typography} from "@mui/material";

function LogoBox(props) {

    return (
        <Box sx={{
            mx: 'auto',
            textAlign: 'center',
            paddingTop: 3,
            paddingBottom: 6,
        }}>
            <Typography variant='h4'>Reliable Transit</Typography>
        </Box>
    );
}

export default LogoBox;