import './App.css';
import {getStops} from "./Api/stop_names";
import {useEffect, useState} from "react";

import {
    createTheme,
    CssBaseline,
    ThemeProvider,
} from "@mui/material";
import DesktopIndex from "./Components/DesktopIndex";
import MobileIndex from "./Components/MobileIndex";
import { Amplify, Analytics, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
Auth.configure({ mandatorySignIn: false});

function unpackStopNamesRoutesDirections(allStopData, setAllStopDataByName, setAllStopDataByID) {
    const allStopDataForName = JSON.parse(JSON.stringify(allStopData))
    const allStopDataByName = new Map();
    for (const stop of allStopDataForName["stop_data"]) {
        const routeMap = new Map();
        for (const route of stop.routes) {
            const dirMap = new Map();
            routeMap.set(route.readable_name, route);
            for (const direction of route.directions) {
                dirMap.set(direction.readable_name, direction);
            }
            route.directions = dirMap;
        }
        stop.routes = routeMap;
        allStopDataByName.set(stop.stop_name, stop);
    }
    setAllStopDataByName(allStopDataByName);

    const allStopDataForID = JSON.parse(JSON.stringify(allStopData))
    const allStopDataByID = new Map();
    for (const stop of allStopDataForID["stop_data"]) {
        const routeMap = new Map();
        for (const route of stop.routes) {
            const dirMap = new Map();
            routeMap.set(route.id, route);
            for (const direction of route.directions) {
                dirMap.set(direction.id, direction);
            }
            route.directions = dirMap;
        }
        stop.routes = routeMap;
        allStopDataByID.set(stop.stop_id, stop);
    }
    setAllStopDataByID(allStopDataByID);
}

function App() {
    const [stopArrivalData, setStopArrivalData] = useState(null)
    const [allStopDataByName, setAllStopDataByName] = useState([])
    const [allStopDataByID, setAllStopDataByID] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loadingAllStopData, setLoadingAllStopData] = useState(false)

    const [width, setWidth]   = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        setLoadingAllStopData(true);
        getStops()
            .then(stops => {
                unpackStopNamesRoutesDirections(stops, setAllStopDataByName, setAllStopDataByID);
                setLoadingAllStopData(false);
                Analytics.record({name: 'loadStops', immediate: true})
            })
        }, []);

    const myTheme = createTheme({
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Work Sans"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            primary: {
                light: '#ffffff',
                main: '#d9e4ec',
                dark: '#a7b2ba',
                contrastText: '#000000',
            },
            secondary: {
                light: '#52c7b8',
                main: '#009688',
                dark: '#00675b',
                contrastText: '#000000',
            },
        },
        components: {
            // Name of the component
            MuiInput: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        padding: '4px 4px 4px 10px',
                    },
                },
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={myTheme}>
                <CssBaseline />
                {width < 1200 ? (
                <MobileIndex
                    allStopDataByName={allStopDataByName}
                    setIsLoading={setIsLoading}
                    setStopArrivalData={setStopArrivalData}
                    loadingAllStopData={loadingAllStopData}
                    isLoading={isLoading}
                    stopArrivalData={stopArrivalData}
                    allStopDataByID={allStopDataByID}
                />
                ) : (
                <DesktopIndex
                    allStopDataByName={allStopDataByName}
                    setIsLoading={setIsLoading}
                    setStopArrivalData={setStopArrivalData}
                    loadingAllStopData={loadingAllStopData}
                    isLoading={isLoading}
                    stopArrivalData={stopArrivalData}
                    allStopDataByID={allStopDataByID}
                />
                )}
            </ThemeProvider>
        </>
    )
}

export default App;
