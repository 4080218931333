import {BottomNavigation, Box } from "@mui/material";
import LogoBox from "./LogoBox";
import FormHeader from "./FormHeader";
import StopRouteDirectionForm from "./StopRouteDirectionForm";
import ResultsCard from "./ResultsCard";
import SummaryCard from "./SummaryCard";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import TramIcon from "@mui/icons-material/Tram";

function MobileIndex(props) {

    return (
        <Box
            sx={{
                bgcolor: 'primary.main',
                minHeight: '100vh',
            }}
        >
            <Box>
                <LogoBox/>
                <FormHeader/>
                <StopRouteDirectionForm
                    allStopDataByName={props.allStopDataByName}
                    setIsLoading={props.setIsLoading}
                    setStopArrivalData={props.setStopArrivalData}
                    loadingAllStopData={props.loadingAllStopData}
                />
                <ResultsCard
                    isLoading={props.isLoading}
                    stopArrivalData={props.stopArrivalData}
                    allStopDataByID={props.allStopDataByID}
                    backgroundColor={'none'}
                />
                <SummaryCard
                    isLoading={props.isLoading}
                    stopArrivalData={props.stopArrivalData}
                    allStopDataByID={props.allStopDataByID}
                    backgroundColor={'none'}
                />
                <Box sx={{
                    marginTop: 2,
                    bgcolor: 'primary.main'
                }}
                >
                    <BottomNavigation sx={{bgcolor: 'primary.main'}}>
                        <DirectionsTransitIcon fontSize='large'
                                               sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <DirectionsBusIcon fontSize='large'
                                           sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <DirectionsBoatIcon fontSize='large'
                                            sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                        <TramIcon fontSize='large' sx={{marginLeft: '0.3vw', marginRight: '0.3vw', color: '#52565F'}}/>
                    </BottomNavigation>
                </Box>
            </Box>
        </Box>
    );
}

export default MobileIndex;