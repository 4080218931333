import {Box, Card, Skeleton, Typography} from "@mui/material";
import React from 'react';

function getReliabilityData(reliabilityRating, twoMinPercent) {
    let reliabilityData = null
    switch (reliabilityRating) {
        case 3:
            reliabilityData = {
                color: "#008000",
                heading: "This commute is reliable!",
                subDescription: "If you take into account how long it takes to walk and use an app or device to leave at the right time, you will reliably get to the train and wait less than 2 minutes- no matter the schedule or how much time is between trains.",
            }
            break;
        case 2:
            reliabilityData = {
                color: "#7e8000",
                heading: "This commute is somewhat reliable.",
                subDescription: "If you take into account how long it takes to walk and use an app or device to leave at the right time, most commutes you will wait less than 2 minutes- no matter the schedule or how much time is between trains.",
            }
            break;
        case 1:
            reliabilityData = {
                color: "#800000",
                heading: "This commute is not very reliable.",
                subDescription: "If you take into account how long it takes to walk and use an app or device to leave at the right time, some commutes you will have to wait a long time because this transit agency gives unreliable estimated arrival updates for this commute.",
            }
            break;
        default:
            break;
    }
    reliabilityData.description = `${twoMinPercent.toFixed(2) * 100}% of trains arrive within 2 minutes of their estimated arrival`
    return reliabilityData
}

export default function SummaryCard(props) {
    if (props.isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Card sx={{padding: 1.5, borderRadius: 8, margin: 2, width: '80%', maxWidth: '1200px', bgcolor: props.backgroundColor}}>
                    <Box sx={{margin: 1}}>
                        <Skeleton variant="rectangular" height={60} sx={{borderRadius: 4, margin: 1}} animation="wave"/>
                        <Skeleton variant="rectangular" height={60} sx={{borderRadius: 4, margin: 1}} animation="wave"/>
                    </Box>
                </Card>
            </Box>
        );
    } else if (props.stopArrivalData == null) {
        return ;
    } else {
        const reliabilityData = getReliabilityData(props.stopArrivalData.reliabilityRating, props.stopArrivalData.twoMinPercent)
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >
                <Card sx={{padding: 1.5, borderRadius: 5, margin: 2, width: '80%', maxWidth: '1200px', bgcolor: props.backgroundColor}}>
                    <Box sx={{margin: 1}}>
                        <Typography variant={"h5"} color={reliabilityData.color}>{reliabilityData.heading}</Typography>
                        <Typography variant={"h6"} sx={{fontSize: 14, margin: 1, fontWeight: 'bold'}}>{reliabilityData.description}</Typography>
                        <Typography variant={"h6"} sx={{fontSize: 14}}>{reliabilityData.subDescription}</Typography>
                    </Box>
                </Card>
            </Box>
        );
    }
}